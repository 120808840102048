
// import Swiper from 'swiper/swiper-bundle.min';
// import 'swiper/swiper-bundle.min.css';
export default {
  props: {
    cameras_forecast: Array,
  },
  data() {
    return {
      mobile: null,
    };
  },
  async mounted() {
    // new Swiper('.Forecast', {
    //   loop: true,
    //   loopFillGroupWithBlank: true,
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   }
    // })
  },
};
