
export default {
  props: {
    playa_info: Array,
  },
  data() {
    return {
      forecasts: [],
    };
  },
  methods: {
    // async fetchData() {
    //   await this.$axios.$get('/api/main/home')
    //     .then(response => {
    //       this.forecasts = response.query_altura;
    //     });
    // }
  },
};
