
import Swiper from "swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";
export default {
  props: {
    articles: Array,
  },
  data() {
    return {};
  },
  mounted() {
    new Swiper('.Article', {
      loop: true,
      loopFillGroupWithBlank: true,
      slidesPerGroup: 1,
      navigation: {
        prevEl: ".fa-chevron-left",
        nextEl: ".fa-chevron-right",
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        1023: {
          slidesPerView: 1,
        },
      },
    });
  },
  //mounted() {
  // this.fetchData();
  //},
  methods: {
    // async fetchData() {
    //   await this.$axios.$get('/api/main/home')
    //     .then(response => {
    //       this.articles = response.articulos1;
    //     });
    // }
  },
};
