// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/img/background/image1.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blue[data-v-7d9d9ead]{color:#1c496b}.line-height-46px[data-v-7d9d9ead]{line-height:46px}.premium[data-v-7d9d9ead]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.premium .cover[data-v-7d9d9ead]{background:linear-gradient(180deg,hsla(0,0%,100%,0),#fff);width:100%}.heading[data-v-7d9d9ead]{color:#1c496b}.box[data-v-7d9d9ead]{color:#3c3c3b}.box .square[data-v-7d9d9ead]{background:#ffa800;box-shadow:2px 2px 2px rgba(0,0,0,.25);height:40px;width:40px}.box .main-title[data-v-7d9d9ead]{font-size:18px;line-height:25px}.box .sub-title[data-v-7d9d9ead]{line-height:14px}.box .in-review[data-v-7d9d9ead]{background:gray}.box .mts[data-v-7d9d9ead]{border:1px solid gray}.box .border-gray[data-v-7d9d9ead]{border-bottom:1px solid gray}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
