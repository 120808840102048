
  export default {
    data(){
      return {
        footer: [],
      }
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      async fetchData() {
        await this.$axios.$get('/api/main/home/settings/')
          .then(response => {
            this.footer = response.footer;
          });
      }
    }
  }
