
  export default {
    head() {
      return {
        title: 'Camaras de surf en vivo - Buscaolas',
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: 'Camaras de surf en las principales playas de Chile'
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: 'Buscaolas - camaras en vivo en las principales playas'
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: 'Camaras de surf en las principales playas de Chile'
          },
            {
            hid: 'og:url',
            name: 'og:url',
            content: 'https://buscaolas.cl'
          },
        ]
      }
    },
    async asyncData({ $axios }) {
      const ArticuloData = await $axios.$get("/api/main/home");
      const art = ArticuloData.articulos1.slice(0,3);
      //console.log('art', art)
      //console.log('ArticuloData', ArticuloData)
      return { ArticuloData, art};
    },
    data(){
      return {
        alert: false,
      }
    }
  }
