import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1a6443a5&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1a6443a5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6443a5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/ubuntu/buscaolas/front-end/components/Header.vue').default,Detect: require('/home/ubuntu/buscaolas/front-end/components/Detect.vue').default,Alertas: require('/home/ubuntu/buscaolas/front-end/components/Alertas.vue').default,Hero: require('/home/ubuntu/buscaolas/front-end/components/Hero.vue').default,Ads: require('/home/ubuntu/buscaolas/front-end/components/Ads.vue').default,CamerasAndSurfForecast: require('/home/ubuntu/buscaolas/front-end/components/CamerasAndSurfForecast.vue').default,JoinTheClub: require('/home/ubuntu/buscaolas/front-end/components/JoinTheClub.vue').default,ForecastCurrent: require('/home/ubuntu/buscaolas/front-end/components/ForecastCurrent.vue').default,LatestArticles: require('/home/ubuntu/buscaolas/front-end/components/LatestArticles.vue').default,Partners: require('/home/ubuntu/buscaolas/front-end/components/Partners.vue').default,Footer: require('/home/ubuntu/buscaolas/front-end/components/Footer.vue').default})
