
  export default {
    props: {
      partners: Array
    },
    data() {
      return {
        partners2: [],
      }
    },
     mounted() {
      // this.fetchData();
    },
    methods: {
      async fetchData() {
        await this.$axios.$get('/api/main/home')
          .then(response => {
            this.partners2 = response.sociosall;
          });
      }
    }
  }
