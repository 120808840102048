
  export default {
    data () {
      return {
        alertas: null,
        alert: false
      }
    },
    async fetch(){
      const DataAlerta = await this.$axios.$get("/api/main/alertas");
      this.alertas = DataAlerta
      if (this.alertas.length > 0) {
        this.alert = true
      } 
    }
  }
