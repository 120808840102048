// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/img/icon/chevron-down.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blue[data-v-27ddb8a2]{color:#1c496b}.gray[data-v-27ddb8a2]{color:#3c3c3b}.line-height-46px[data-v-27ddb8a2]{line-height:46px}.swiper-button-next[data-v-27ddb8a2],.swiper-button-prev[data-v-27ddb8a2]{top:39%}.swiper-button-next[data-v-27ddb8a2]:after,.swiper-button-prev[data-v-27ddb8a2]:after{content:\"none\"}.swiper-button-next[data-v-27ddb8a2]{right:0}.swiper-button-prev[data-v-27ddb8a2]{left:0}.border-green[data-v-27ddb8a2]{border-color:#00a99d}.fa-chevron-left[data-v-27ddb8a2],.fa-chevron-right[data-v-27ddb8a2]{color:#fff}.sort-by[data-v-27ddb8a2]{border:1px solid #1c496b}select[data-v-27ddb8a2]{-webkit-appearance:none;-moz-appearance:none;appearance:none;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:85% 50%;background-size:20px 12px;border:none;height:40px;width:120px}.heading[data-v-27ddb8a2]{font-size:25px;line-height:35px}.read-more[data-v-27ddb8a2]{border:1px solid #3c3c3b;height:40px;letter-spacing:.25em;width:160px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
